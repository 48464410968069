import { FC, useState, createContext } from 'react';
type HeaderContext = {
  company: string;
  branch: string;
  setCompany: (company: string) => void;
  setBranch: (branch: string) => void;
  profileDetails: any;
  setProfileDetails: any;
  permissionDetails: any;
  setPermissionDetails: (details: any) => void;
  enableCentralizedData: any;
  setEnabledCentralizedData: (enabled: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HeaderContext = createContext<HeaderContext>({} as HeaderContext);

export const HeaderProvider: FC = ({ children }) => {
  const [company, setCompany] = useState('');
  const [enableCentralizedData, setEnabledCentralizedData] = useState(false);
  const [profileDetails, setProfileDetails] = useState<any>(null);
  const [permissionDetails, setPermissionDetails] = useState<any>([]);

  const [branch, setBranch] = useState('');

  return (
    <HeaderContext.Provider
      value={{
        company,
        setCompany,
        branch,
        setBranch,
        profileDetails,
        setProfileDetails,
        permissionDetails,
        setPermissionDetails,
        enableCentralizedData,
        setEnabledCentralizedData
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
