import api from 'src/api/api';

const ACCOUNT_API_URL = `${process.env.REACT_APP_ACCOUNTS_API_URL}`;
const COMPANY_API_URL = `${process.env.REACT_APP_CURRENT_COMPANY_API_URL}`;
const CURRENT_COMPANY_URL = `${process.env.REACT_APP_CURRENT_COMPANY_API_URL}`;
const REPORTS_API_URL = `${process.env.REACT_APP_API_URL}`

//Get Current Company Details
const getCurrentCompanyDetails = async () => {
  try {
    const response: any = await api.actionHandler({
      url: `${CURRENT_COMPANY_URL}/Company/GetCurrentCompany`,
      method: 'GET'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCurrencyDetails = async ({ currencyID }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${CURRENT_COMPANY_URL}/Currency/${currencyID}`,
      method: 'GET'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Get Tax List
const getTaxList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${ACCOUNT_API_URL}/Tax?companyId=${company}&branchId=${branch}`,
      method: 'GET'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Get Payment Method List
const getPaymentMethodList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${ACCOUNT_API_URL}/Ledger?companyId=${company}&branchId=${branch}`,
      method: 'GET'
    });
    let paymentModeList = response?.data?.data?.filter(
      (item) => item.groupId === 14 || item.groupId === 13
    );
    let filterPaymentModes = paymentModeList?.filter(
      (item) => item.isListInPaymentModes === true
    );
    return filterPaymentModes;
  } catch (error) {
    throw error;
  }
};

//Get Purchase Series List
const getPurchaseSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'PURCHASE'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get Employee List
const getEmployeeList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Employee?companyId=${company}&branchId=${branch}&pageSize=15`,
      method: 'GET'
    });
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

//Get Warehouse List
const getWarehouseList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/StockLocation?companyId=${company}&branchId=${branch}`,
      method: 'GET'
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};


//Get Company List
const getCompanyList = async () => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Company`,
      method: 'GET'
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

//Get Branch List
const getBranchList = async (companyId: string) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Branch?companyId=${companyId}`,
      method: 'GET'
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

//Get Sale Series List
const getSaleSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'SALE'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get Purchase Return Series
const getPurchaseReturnSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'PURCHASE_RETURN'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get Sale Return Series
const getSaleReturnSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'SALE_RETURN'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get Quotation Series
const getQuotationSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'QUOTATION'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get PurchaseOrder Series
const getPurchaseOrderSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'PURCHASE_ORDER'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get SaleOrder Series
const getSaleOrderSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'SALE_ORDER'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get Deliver Notes Series
const getDeliveryNoteSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'DELIVERY_NOTE'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get Deliver Note Receipt Series
const getDeliveryNoteReceiptSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'DELIVERY_NOTE_RECEIPT'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Get Ledger Dropdown
const getLedgerDropdown = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${ACCOUNT_API_URL}/Ledger?companyId=${company}&branchId=${branch}`,
      method: 'GET'
    });
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

//Get StockTransfer Series List
const getStockTransferSeriesList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Series?branchId=${branch}&companyId=${company}`,
      method: 'GET'
    });
    let filteredData = response.data.data.filter(
      (item) => item.transactionType === 'STOCK_TRANSFER_INTERNAL'
    );
    return filteredData;
  } catch (error) {
    throw error;
  }
};

//Manufacture List
const getManufactureList = async ({ company, branch }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${REPORTS_API_URL}Manufacture?companyId=${company}&branchId=${branch}&pageSize=200`,
      method: 'GET'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  getPaymentMethodList,
  getPurchaseSeriesList,
  getEmployeeList,
  getWarehouseList,
  getCompanyList,
  getBranchList,
  getSaleSeriesList,
  getQuotationSeriesList,
  getPurchaseReturnSeriesList,
  getSaleReturnSeriesList,
  getPurchaseOrderSeriesList,
  getSaleOrderSeriesList,
  getDeliveryNoteSeriesList,
  getDeliveryNoteReceiptSeriesList,
  getLedgerDropdown,
  getCurrentCompanyDetails,
  getTaxList,
  getCurrencyDetails,
  getStockTransferSeriesList,
  getManufactureList
};
