import { FC, useState, createContext } from 'react';
type POSContext = {
  transactionType: string;
  setTransactionType: (company: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const POSContext = createContext<POSContext>({} as POSContext);

export const POSProvider: FC = ({ children }) => {
  const [transactionType, setTransactionType] = useState('sales');
  


  return (
    <POSContext.Provider
      value={{
        transactionType,
        setTransactionType,
      }}
    >
      {children}
    </POSContext.Provider>
  );
};
