import * as React from 'react';
import Box from '@mui/material/Box';
import { Collapse, Divider, Hidden, IconButton, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { HeaderContext } from 'src/contexts/HeaderContext';
import { useQuery } from '@tanstack/react-query';
import { getCurrentCompanyDetails } from 'src/api/common/dropdownList';
import axios from 'axios';
import { useTranslate } from 'src/locales';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import CompanyUpdateModal from '../Masters/Company/Modals/CompanyUpdateModal';
import EditIcon from '@mui/icons-material/Edit';
import BusinessIcon from '@mui/icons-material/Business';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function ProfileModal({ toggle, setToggle }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [toggleEdit, setToggleEdit] = React.useState(false);
  const { profileDetails } = React.useContext(HeaderContext);
  const [viewPin, setViewPin] = React.useState(false);
  const { t } = useTranslate();
  const handleLogout = (e): void => {
    e.preventDefault();
    localStorage.removeItem('userName');
    localStorage.removeItem('admin-token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRoleId');
    localStorage.removeItem('parentCustomerId');
    localStorage.removeItem('lastActivity');
    localStorage.removeItem('counterId');
    localStorage.removeItem('workPeriodId');
    localStorage.removeItem('branchId');
    localStorage.removeItem('maintenanceLogin');
    sessionStorage.removeItem('reportFilterData');
    localStorage.removeItem('isEnableCentralizedData');

    delete axios.defaults.headers.common.Authorization;
    window.location.href = '/auth/sign-in';
  };

  //Current Company Details
  const { data: companyDetails, } = useQuery<any>({
    queryKey: ['companyDetails'],
    queryFn: () => getCurrentCompanyDetails(),
    refetchOnWindowFocus: false,
    enabled: toggle === true ? true : false
  });

  return (
    <>
      {/* @ts-ignore */}
      <SwipeableDrawer
        ModalProps={{
          onBackdropClick: () => setToggle(false)
        }}
        anchor="right"
        open={toggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: '26%',
            zIndex: 9999,
            [theme.breakpoints.down('sm')]: {
              width: '100%'
            },
            //for mobile
            '@media (max-width: 600px)': {
              width: '100%'
            },
            //for tablet
            '@media (min-width: 600px) and (max-width: 960px)': {
              width: '70%'
            },
            borderRadius: '0 0 0 1rem',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            height: '100%',
            backgroundColor: '#fff',
          }
        }}
        onClose={() => setToggle(false)}
      >
        <Box sx={{ px: 3, py: 2.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <img style={{ borderRadius: '4px' }} width={50} height={50} src={'https://d9-wret.s3.us-west-2.amazonaws.com/assets/palladium/production/s3fs-public/styles/full_width/public/thumbnails/image/Placeholder_person.png?itok=w8HA7eHV'} />
              <Box>
                <Typography variant='h5'>{profileDetails?.customerName || '-'}</Typography>
                <Typography variant='body1' sx={{ mt: .5, color: 'gray', fontSize: 12 }}>{profileDetails?.email || '-'}</Typography>
              </Box>
            </Box>
            <Box>
              <CloseIcon sx={{ cursor: 'pointer', color: 'red' }} onClick={() => setToggle(false)} />
            </Box>
          </Box>
          <Typography variant='body1' sx={{ mt: .5, color: 'gray', fontSize: 12 }}>{t("Profile.UserID")} : {profileDetails?.userName || '-'}</Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography variant='body1' sx={{ mt: .5, color: 'gray', fontSize: 12 }}>Customer ID : {profileDetails?.customerId || '-'}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant='body1' sx={{ mt: .5, color: 'gray', fontSize: 12 }}>PIN : {viewPin ? profileDetails?.customerPin : '****'}</Typography>
              <IconButton
                onClick={() =>
                  setViewPin(!viewPin)
                }
                sx={{ p: 0,mt:.5 }}
              >
                {!viewPin ? (
                  <VisibilityIcon sx={{ fontSize: '14px', backgroundColor: '#F1F1F1' }} />
                ) : (
                  <VisibilityOffIcon sx={{ fontSize: '14px', backgroundColor: '#F1F1F1' }} />
                )}
              </IconButton>
            </Box>
          </Box>
          <Hidden lgUp>
            <Typography variant='body1' color='primary' sx={{ mt: .5, fontSize: 12.5 }}>{t("Profile.Company")} : {companyDetails?.companyName || '-'}</Typography>
          </Hidden>
        </Box>
        <Divider />
        <Box sx={{ px: 3, py: 1.1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='body1' sx={{ fontSize: 14 }}>{t("Profile.Account")}</Typography>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between', gap: 1 }} onClick={handleLogout}>
              <LogoutIcon sx={{ fontSize: 18, color: 'red' }} />
              <Typography variant='body1' sx={{ fontSize: 14, color: 'red' }}>{t("Profile.SignOut")}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ px: 3, py: 1.1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: .5 }}>
            <BusinessIcon fontSize='small' sx={{ color: '#0071BC', cursor: 'pointer', fontSize: 16 }} onClick={() => setToggleEdit(true)} />
            <Typography variant='body1' sx={{ fontSize: 14, color: '#0071BC', cursor: 'pointer' }} onClick={() => setToggleEdit(true)}>My Company</Typography>
          </Box>
          <EditIcon fontSize='small' sx={{ color: '#0071BC', cursor: 'pointer', fontSize: 16 }} onClick={() => setToggleEdit(true)} />
        </Box>
        <Divider />
        <Box sx={{ px: 3, py: 1.1 }}>
          <Typography variant='body1' sx={{ mt: .5, fontSize: 14 }}>{t("Profile.PlanName")} : {profileDetails?.planName || '-'}</Typography>
        </Box>
        <Divider />
        {Number(profileDetails?.expiryWithInDays) > 0 && <><Box sx={{ px: 3, py: 1.1 }}>
          <Typography variant='body1' sx={{ fontSize: 14 }}>{t("Profile.PlanExpiresIn")} {profileDetails?.expiryWithInDays || 0} {t("Profile.days")}</Typography>
        </Box>
          <Divider />
        </>}
        <Box sx={{ px: 3, py: 1.1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='body1' sx={{ fontSize: 14 }}>Additional Users : {profileDetails?.license || 0}</Typography>
          {profileDetails?.licenseCharge !== 0 && <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between', gap: 1 }}>
              <AddIcon sx={{ fontSize: 18 }} />
              <Typography variant='body1' sx={{ fontSize: 14 }}
                onClick={(e) => {
                  e.preventDefault();
                  if (profileDetails?.isTrialPlan || profileDetails?.isExpired) {
                    enqueueSnackbar(t("Profile.YouCantAddMoreLicense"), { variant: 'error' });
                    return;
                  }
                  navigate('/add-payment')
                }}>Add More User</Typography>
            </Box>
          </Box>}
        </Box>
        <Divider />
        <Box sx={{ position: 'absolute', bottom: 0, right: 0, left: 0, mb: 1 }}>
          <Divider />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, px: 3, py: 1.1 }}>
            <Typography variant='body1' sx={{ fontSize: 14 }}>For Support : </Typography>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{ color: 'white', p: 0, backgroundColor: '#0071BC', '&:hover': { backgroundColor: '#0071BC', } }}
            >
              {open ? (
                <ExpandLessRoundedIcon />
              ) : (
                <ExpandMoreRoundedIcon />
              )}
            </IconButton>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ px: 2, py: 1.1 }}>
            <Box sx={{ border: '1px solid #F1F1F1', borderRadius: '4px', p: 1.2 }}>
              <Typography variant='body1' sx={{ fontSize: 14, fontWeight: 'bold', mb: 1 }}>Email</Typography>
              <Typography variant='body1' sx={{ fontSize: 14, ml: 2 }}>support@mysaleapp.com</Typography>
              <Typography variant='body1' sx={{ fontSize: 14, ml: 2 }}>sales@mysaleapp.com</Typography>
              <Typography variant='body1' sx={{ fontSize: 14, fontWeight: 'bold', my: 1 }}>Whatsapp</Typography>
              <Typography variant='body1' sx={{ fontSize: 14, ml: 2 }}>+971 50 533 5912</Typography>
              <Typography variant='body1' sx={{ fontSize: 14, ml: 2 }}>+971 50 120 7597</Typography>
              <Typography variant='body1' sx={{ fontSize: 14, ml: 2 }}>+971 50 376 7273</Typography>
              <Typography variant='body1' sx={{ fontSize: 14, ml: 2 }}>+971 55 235 5319</Typography>
            </Box>
          </Collapse>
          <Divider />
        </Box>
      </SwipeableDrawer>
      {toggleEdit ? (<CompanyUpdateModal toggle={toggleEdit} setToggle={setToggleEdit} companyID={companyDetails?.id} />) : null}
    </>
  );
};

export default React.memo(ProfileModal);
  