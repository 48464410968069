import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router-dom';
import BaseLayout from 'src/layouts/BaseLayout';
import DashboardLayout from './layouts/SidebarLayout';
import Loading from './assets/Loading';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );

// Auth
const SignIn = Loader(lazy(() => import('src/content/auth/index')));
const Register = Loader(lazy(() => import('src/content/auth/Register')));
const ForgotPassword = Loader(lazy(() => import('src/content/auth/ForgotPassword')));
const Pricing = Loader(lazy(() => import('src/content/other/Pricing')));

// Dashboards
const Dashboard = Loader(lazy(() => import('src/content/dashboards/Dashboard/Dashboard')));

//Account Reports
const Daybook = Loader(lazy(() => import('src/content/dashboards/AccountReports/Daybooks')));
const Ledger = Loader(lazy(() => import('src/content/dashboards/AccountReports/Ledger')));
const Cashbook = Loader(lazy(() => import('src/content/dashboards/AccountReports/Cashbook')));
const Bankbook = Loader(lazy(() => import('src/content/dashboards/AccountReports/Bankbook')));
const VoucherReport = Loader(lazy(() => import('src/content/dashboards/AccountReports/VoucherReport')));
const InputTaxReport = Loader(lazy(() => import('src/content/dashboards/AccountReports/TaxReports/InputTaxReports')));
const OutputTaxReport = Loader(lazy(() => import('src/content/dashboards/AccountReports/TaxReports/OutputTaxReports')));
const VatSummary = Loader(lazy(() => import('src/content/dashboards/AccountReports/TaxReports/VatSummary')));
const ProfitAndLoss = Loader(lazy(() => import('src/content/dashboards/AccountReports/ProfitAndLoss')));
const CreditorsReport =Loader(lazy(() => import('src/content/dashboards/AccountReports/CreditorsReport')))
const DebtorsReport =Loader(lazy(() => import('src/content/dashboards/AccountReports/DebtorsReport')))
const AgeWiseDebtorsReport =Loader(lazy(() => import('src/content/dashboards/AccountReports/AgeWiseDebtorsReport')))
const AgeWiseCreditorsReport =Loader(lazy(() => import('src/content/dashboards/AccountReports/AgeWiseCreditorsReport')))
const PDCReport =Loader(lazy(() => import('src/content/dashboards/AccountReports/PostDatedChequeReport')))
const TrialBalance =Loader(lazy(() => import('src/content/dashboards/AccountReports/TrialBalance')))
const BalanceSheet =Loader(lazy(() => import('src/content/dashboards/AccountReports/BalanceSheet')));
const TransactionSummary =Loader(lazy(() => import('src/content/dashboards/AccountReports/TransactionSummary')));
const SalesmanWisePaymentCollectionReport = Loader(lazy(() => import('src/content/dashboards/AccountReports/SalesmanWisePaymentCollectionReport')));
const OverDueSalesInvoiceReport = Loader(lazy(() => import('src/content/dashboards/AccountReports/OverDueSalesInvoiceReport')));
const OverDuePurchaseInvoiceReport = Loader(lazy(() => import('src/content/dashboards/AccountReports/OverDuePurchaseInvoiceReport')));
const BillWisePendingSales = Loader(lazy(() => import('src/content/dashboards/AccountReports/BillWisePendingSales')));
const BillWisePendingPurchase = Loader(lazy(() => import('src/content/dashboards/AccountReports/BillWisePendingPurchase')));
const PdcCalendar = Loader(lazy(() => import('src/content/dashboards/AccountReports/PdcCalendar')));
const ChartOfAccounts = Loader(lazy(() => import('src/content/dashboards/AccountReports/ChartOfAccounts')));
const StatementOfAccounts = Loader(lazy(() => import('src/content/dashboards/AccountReports/StatementOfAccounts')));
const AccountGroupReport = Loader(lazy(() => import('src/content/dashboards/AccountReports/AccountGroupReport')));
//Inventory Reports
const PurchaseReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/PurchaseReport')));
const SaleReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/SalesReport')));
const PurchaseReturnReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/PurchaseReturnReport')));
const SaleReturnReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/SaleReturnReport')));
const QuotationReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/Quotations')));
const QuotationRequestIssueReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/QuotationRequestIssueReport')));
const QuotationRequestReceivedReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/QuotationRequestReceivedReport')));
const PurchaseOrdersReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/PurchaseOrdersReport')));
const SalesOrdersReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/SalesOrderReport')));
const DeliveryNoteReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/DeliveryNoteReport')));
const DeliverNoteReceiptReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/DeliveryNoteReceiptReport')));
const InternalStockTransferReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/InternalStockTransferReport')));
const ExternalStockTransferReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ExternalStockTransferReport')));
const StockMasterReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/StockMasterReport')));
const BillWiseMarginReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/BillWiseMarginReport')));
const ItemWiseMarginReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ItemWiseMarginReport')));
const ItemWiseTransactionsReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ItemWiseTransactionsReport')));
const ZReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/EODReport/')));
const OverallZReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/OverallZReport/')));
const OpeningStockReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/OpeningStockReport')));
const DetailedStockReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/StockRegisterReport')));
const ItemWiseStockReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ItemWiseStockReport/')));
const StockAdjustmentReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/StockAdjustmentReport/')));
const AdjustmentReport =Loader(lazy(() => import('src/content/dashboards/InventoryReports/AdjustmentReport')));
const CancelledTransactions= Loader(lazy(() => import('src/content/dashboards/InventoryReports/CancelledTransactions')));
const SerialNoTransactions= Loader(lazy(() => import('src/content/dashboards/InventoryReports/SerialNoTransactions')));
const SalesReportDetailed = Loader(lazy(() => import('src/content/dashboards/InventoryReports/SalesReportDetailed')));
const ServiceReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ServiceReport')));
const MonthlySalesAnalysis = Loader(lazy(() => import('src/content/dashboards/InventoryReports/MonthlySalesAnalysis')));
const MonthlyPurchaseAnalysis = Loader(lazy(() => import('src/content/dashboards/InventoryReports/MonthlyPurchaseAnalysis')));
const DailySalesReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/DailySalesReport')));
const DailyPurchaseReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/DailyPurchaseReport')));
const ServiceExpiryReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ServiceExpiryReport')));
const ItemExpiryReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ItemExpiryReport')));
const ReorderLevelReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ReorderLevelReport')));
const MinimumStockLevelReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/MinimumStockLevelReport')));
const MaximumStockLevelReport = Loader(lazy(() => import('src/content/dashboards/InventoryReports/MaximumStockLevelReport')));
const ForexTransactionsReport =  Loader(lazy(() => import('src/content/dashboards/InventoryReports/ForexTransactionsReport')));
const ItemWiseSalesOrderSummary = Loader(lazy(() => import('src/content/dashboards/InventoryReports/ItemWiseSalesOrderSummary')));

//Masters
const StockLocationCategory = Loader(lazy(() => import('src/content/dashboards/Masters/StockLocationCategory')));
const Vehicle = Loader(lazy(() => import('src/content/dashboards/Masters/Vehicle')));
const CustomerCategory = Loader(lazy(() => import('src/content/dashboards/Masters/CustomerCategory')));
const Country = Loader(lazy(() => import('src/content/dashboards/Masters/Country')));
const States = Loader(lazy(() => import('src/content/dashboards/Masters/States')));
const Company = Loader(lazy(() => import('src/content/dashboards/Masters/Company')));
const Branch = Loader(lazy(() => import('src/content/dashboards/Masters/Branch')));
const Items = Loader(lazy(() => import('src/content/dashboards/Masters/Items')));
const Categories = Loader(lazy(() => import('src/content/dashboards/Masters/Categories'))); 
const Manufacturer = Loader(lazy(() => import('src/content/dashboards/Masters/Manufacturing'))); 
const Units = Loader(lazy(() => import('src/content/dashboards/Masters/Units')));
const Employees = Loader(lazy(() => import('src/content/dashboards/Masters/Employees')));
const StockLocations = Loader(lazy(() => import('src/content/dashboards/Masters/StockLocation')));
const Series = Loader(lazy(() => import('src/content/dashboards/Masters/Series')));
const BarcodeSeries = Loader(lazy(() => import('src/content/dashboards/Masters/BarcodeSeries')));
const Currency = Loader(lazy(() => import('src/content/dashboards/Masters/Currency')));
const AccountGroup = Loader(lazy(() => import('src/content/dashboards/Masters/AccountGroup')));
const LedgerMaster = Loader(lazy(() => import('src/content/dashboards/Masters/LedgerMaster')));
const Customer = Loader(lazy(() => import('src/content/dashboards/Masters/Customer')));
const Vendors = Loader(lazy(() => import('src/content/dashboards/Masters/Vendors')));
const PriceListMaster = Loader(lazy(()=>import('src/content/dashboards/Masters/PriceList/PriceListMaster')))
const PriceListCreate = Loader(lazy(()=>import('src/content/dashboards/Masters/PriceList/PriceListCreate')))
const PriceListUpdate = Loader(lazy(()=>import('src/content/dashboards/Masters/PriceList/PriceListUpdate')))
const SellingPriceUpdation = Loader(lazy(()=>import('src/content/dashboards/Masters/SellingPriceUpdation')))
const UserManagement = Loader(lazy(()=>import('src/content/dashboards/Settings/UserManagement')))
const UserRole = Loader(lazy(()=>import('src/content/dashboards/Settings/UserRole')))
const Counters = Loader(lazy(()=>import('src/content/dashboards/Masters/Counters')))
const Bank = Loader(lazy(()=>import('src/content/dashboards/Masters/Bank')))
const StockAdjustmentType = Loader(lazy(()=>import('src/content/dashboards/Masters/StockAdjustmentType')));
const Areas = Loader(lazy(()=>import('src/content/dashboards/Masters/Areas')));

//Inventory
const Purchase = Loader(lazy(()=>import('src/content/dashboards/Inventory/Purchase/Purchase')))
const Sales =Loader(lazy(()=>import('src/content/dashboards/Inventory/Sales/Sales')))
const SalesReturn =Loader(lazy(()=>import('src/content/dashboards/Inventory/SalesReturn/SalesReturn')))
const PurchaseReturn =Loader(lazy(()=>import('src/content/dashboards/Inventory/PurchaseReturn/PurchaseReturn')))
const PurchaseOrder =Loader(lazy(()=>import('src/content/dashboards/Inventory/PurchaseOrder/PurchaseOrder')))
const SaleOrder =Loader(lazy(()=>import('src/content/dashboards/Inventory/SalesOrder/SaleOrder')))
const DeliveryNote =Loader(lazy(()=>import('src/content/dashboards/Inventory/DeliveryNote/DeliveryNote')))
const DeliveryNoteReceipt =Loader(lazy(()=>import('src/content/dashboards/Inventory/DeliveryNoteReceipt/DeliveryNoteReceipt')))
const InternalStockTransfer =Loader(lazy(()=>import('src/content/dashboards/Inventory/InternalStockTransfer/InternalStockTransfer')))
const ExternalStockTransfer =Loader(lazy(()=>import('src/content/dashboards/Inventory/ExternalStockTransfer/ExternalStockTransfer')))
const Quotation = Loader(lazy(() => import('src/content/dashboards/Inventory/Quotation/Quotation')));
const QuotationRequestIssue = Loader(lazy(() => import('src/content/dashboards/Inventory/QuotationRequestIssue/QuotationRequestIssue')));
const QuotationRequestReceived = Loader(lazy(() => import('src/content/dashboards/Inventory/QuotationRequestReceived/QuotationRequestReceived')));
const StockAdjustment = Loader(lazy(() => import('src/content/dashboards/Inventory/StockAdjustment/StockAdjustment')));

const Service = Loader(lazy(() => import('src/content/dashboards/Inventory/Services/Service')));

//Accounts
const PaymentVoucher = Loader(lazy(()=>import('src/content/dashboards/Accounts/PaymentVoucher')))
const ReceiptVoucher = Loader(lazy(()=>import('src/content/dashboards/Accounts/ReceiptVoucher')))
const Journal = Loader(lazy(()=>import('src/content/dashboards/Accounts/Journal')))
const Contra = Loader(lazy(()=>import('src/content/dashboards/Accounts/Contra')))
const DebitNote = Loader(lazy(()=>import('src/content/dashboards/Accounts/DebitNote')))
const CreditNote = Loader(lazy(()=>import('src/content/dashboards/Accounts/CreditNote')))
const BankReconciliation = Loader(lazy(() => import('src/content/dashboards/Accounts/BankReconciliation')));

const WorkPeriod = Loader(lazy(()=>import('src/content/dashboards/POS/WorkPeriod')))


//Pos
const POS = Loader(lazy(()=>import('src/content/dashboards/POS/POS')))

//Print settings
const ChooseTemplate = Loader(lazy(()=> import('src/content/dashboards/Settings/Print/ChooseTemplate/ChooseTemplate')))
const Print = Loader(lazy(() => import('src/content/dashboards/Settings/Print/index')))
const ChooseTemplateThermal = Loader(lazy(() => import('src/content/dashboards/Settings/Print/ChooseTemplateThermal/ChooseTemplateThermal')))

//Dashboard settings
const DashboardOptions = Loader(lazy(() => import('src/content/dashboards/Settings/Dashboard')));
const Options = Loader(lazy(() => import('src/content/dashboards/Settings/Options')));
const GeneralSettings = Loader(lazy(() => import('src/content/dashboards/Settings/GeneralSettings')));
const BarcodeSettings = Loader(lazy(() => import('src/content/dashboards/Settings/BarcodeSettings/Index')));

// Status
const Status404 = Loader(lazy(() => import('src/content/StatusPages/Status404/index')));

const Subscriptions = Loader(lazy(()=>import('src/content/dashboards/Subscriptions/Index')));
const AddPayment = Loader(lazy(()=>import('src/content/dashboards/Profile/PaymentScreen')));

const Maintenance = Loader(lazy(() => import('src/content/dashboards/Settings/Maintenance')));
const BaseCurrencyAdjustment = Loader(lazy(() => import('src/content/dashboards/Settings/BaseCurrencyAdjustment')));

const routes: RouteObject[] = [
  
  // Auth Pages Routes
  {
    path: 'auth', element: <BaseLayout />,
    children: [
      { path: '/auth/sign-in', element: <SignIn /> },
      { path: '/auth/sign-up', element: <Register /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/pricing', element: <Pricing /> },
    ]
  },
  {
    path: 'other', element: <BaseLayout />,
    children: [
      { path: '/other/pricing', element: <Pricing /> },
    ]
  },
  // Dashboards Pages Routes
  { path: '/', element: <Navigate to="/dashboard" />, index: true },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Navigate to="dashboard" replace /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'daybooks', element: <Daybook /> },
      { path: 'cashbook', element: <Cashbook /> },
      { path: 'bankbook', element: <Bankbook /> },
      { path: 'voucher-report', element: <VoucherReport /> },
      { path: 'input-tax', element: <InputTaxReport /> },
      { path: 'output-tax', element: <OutputTaxReport /> },
      { path: 'vat-summary', element: <VatSummary /> },
      { path: 'profit-loss', element: <ProfitAndLoss /> },
      { path: 'ledger', element: <Ledger /> },
      { path: 'purchase-report', element: <PurchaseReport /> },
      { path: 'sales-report', element: <SaleReport /> },
      { path: 'purchase-return-report', element: <PurchaseReturnReport /> },
      { path: 'sales-return-report', element: <SaleReturnReport /> },
      { path: 'quotation', element: <QuotationReport /> },
      { path: 'quotation-request-issue', element: <QuotationRequestIssueReport /> },
      { path: 'quotation-request-received', element: <QuotationRequestReceivedReport /> },
      { path: 'purchase-orders', element: <PurchaseOrdersReport /> },
      { path: 'sale-orders', element: <SalesOrdersReport /> },
      { path: 'delivery-notes', element: <DeliveryNoteReport /> },
      { path: 'delivery-note-receipts', element: <DeliverNoteReceiptReport /> },
      { path: 'internal-stock-transfers', element: <InternalStockTransferReport /> },
      { path: 'external-stock-transfers', element: <ExternalStockTransferReport /> },
      { path: 'bill-wise-margin', element: <BillWiseMarginReport /> },
      { path: 'item-wise-margin', element: <ItemWiseMarginReport /> },
      { path: 'item-wise-transactions', element: <ItemWiseTransactionsReport /> },
      { path: 'stocks', element: <StockMasterReport /> },
      { path: 'z-report', element: <ZReport /> },
      { path: 'overall-z-report', element: <OverallZReport /> },
      { path: 'opening-stock-report', element: <OpeningStockReport /> },
      { path: 'detailed-stock-report', element: <DetailedStockReport /> },
      { path: 'item-wise-stock-report', element: <ItemWiseStockReport /> },
      { path: 'stock-adjustment-report', element: <StockAdjustmentReport /> },
      { path: 'adjustment-report', element: <AdjustmentReport /> },
      { path: 'cancelled-transactions', element: <CancelledTransactions /> },
      { path: 'serial-no-transactions', element: <SerialNoTransactions /> },
      { path: 'sales-report-detailed', element: <SalesReportDetailed /> },
      { path: 'service-report', element: <ServiceReport /> },
      { path: 'monthly-sales-analysis', element: <MonthlySalesAnalysis /> },
      { path: 'monthly-purchase-analysis', element: <MonthlyPurchaseAnalysis /> },
      { path: 'daily-sales-report', element: <DailySalesReport /> },
      { path: 'daily-purchase-report', element: <DailyPurchaseReport /> },
      { path: 'service-expiry-report', element: <ServiceExpiryReport /> },
      { path: 'item-expiry-report', element: <ItemExpiryReport /> },
      { path: 'reorder-level-report', element: <ReorderLevelReport /> },
      { path: 'minimum-stock-level-report', element: <MinimumStockLevelReport /> },
      { path: 'maximum-stock-level-report', element: <MaximumStockLevelReport /> },
      { path: 'forex-transactions-report', element: <ForexTransactionsReport />},
      { path: 'item-wise-sales-order-summary', element: <ItemWiseSalesOrderSummary />},

      { path: 'pdc-calendar', element: <PdcCalendar /> },
      { path: 'salesman-wise-payment-collection-report', element: <SalesmanWisePaymentCollectionReport /> },
      { path: 'overdue-sales-invoice-report', element: <OverDueSalesInvoiceReport /> },
      { path: 'overdue-purchase-invoice-report', element: <OverDuePurchaseInvoiceReport /> },
      { path: 'pending-bill-report', element: <BillWisePendingSales /> },
      { path: 'bill-wise-pending-purchase', element: <BillWisePendingPurchase /> },
      { path: 'chart-of-accounts', element: <ChartOfAccounts /> },
      { path: 'statement-of-accounts', element: <StatementOfAccounts />},
      { path: 'account-group-report', element: <AccountGroupReport />},

      { path: 'masters/items', element: <Items /> },
      { path: 'masters/categories', element: <Categories /> },
      { path: 'masters/manufacturers', element: <Manufacturer /> },
      { path: 'masters/units', element: <Units /> },
      { path: 'masters/employees', element: <Employees /> },
      { path: 'masters/stock-location', element: <StockLocations /> },
      { path: 'masters/series', element: <Series /> },
      { path: 'masters/barcode-series', element: <BarcodeSeries /> },
      { path: 'masters/currency', element: <Currency /> },
      { path: 'masters/account-group', element: <AccountGroup /> },
      { path: 'masters/ledger-master', element: <LedgerMaster /> },
      { path: 'masters/customers', element: <Customer /> },
      { path: 'masters/vendors', element: <Vendors /> },
      { path: 'masters/stock-location-category', element: <StockLocationCategory /> },
      { path: 'masters/vehicle', element: <Vehicle /> },
      { path: 'masters/counters', element: <Counters /> },
      { path: 'masters/customer-category', element: <CustomerCategory /> },
      { path: 'masters/country', element: <Country /> },
      { path: 'masters/states', element: <States /> },
      { path: 'masters/company', element: <Company /> },
      { path: 'masters/branch', element: <Branch /> },
      { path: 'masters/bank', element: <Bank /> },
      { path: 'masters/price-list', element: <PriceListMaster /> },
      { path: 'masters/price-list-create', element: <PriceListCreate /> },
      { path: 'masters/price-list-update/:priceListID', element: <PriceListUpdate /> },
      { path: 'masters/selling-price-updation', element: <SellingPriceUpdation /> },
      { path: 'masters/stock-adjustment-type', element: <StockAdjustmentType /> },
      { path: 'masters/areas', element: <Areas /> },

      { path: 'user-management', element: <UserManagement /> },
      { path: 'user-roles', element: <UserRole /> },
      { path: 'accounts/payment-voucher', element: <PaymentVoucher /> },
      { path: 'accounts/payment-voucher/:voucherID', element: <PaymentVoucher /> },
      { path: 'accounts/receipt-voucher', element: <ReceiptVoucher /> },
      { path: 'accounts/receipt-voucher/:voucherID', element: <ReceiptVoucher /> },
      { path: 'accounts/journal', element: <Journal /> },
      { path: 'accounts/journal/:voucherID', element: <Journal /> },
      { path: 'accounts/contra', element: <Contra /> },
      { path: 'accounts/contra/:voucherID', element: <Contra /> },
      { path: 'accounts/debit-note', element: <DebitNote /> },
      { path: 'accounts/debit-note/:voucherID', element: <DebitNote /> },
      { path: 'accounts/credit-note', element: <CreditNote /> },
      { path: 'accounts/credit-note/:voucherID', element: <CreditNote /> },
      { path: 'accounts/creditors-report', element: <CreditorsReport /> },
      { path: 'accounts/debtors-report', element: <DebtorsReport /> },
      { path: 'accounts/age-wise-debtors-report', element: <AgeWiseDebtorsReport />},
      { path: 'accounts/age-wise-creditors-report', element: <AgeWiseCreditorsReport />},
      { path: 'accounts/pdc-report', element: <PDCReport /> },
      { path: 'accounts/trial-balance', element: <TrialBalance /> },
      { path: 'accounts/balance-sheet', element: <BalanceSheet /> },
      { path: 'accounts/transaction-summary', element: <TransactionSummary /> },
      { path: 'accounts/bank-reconciliation', element: <BankReconciliation />},

      { path: 'inventory/purchase', element: <Purchase /> },
      { path: 'inventory/purchase/:purchaseID', element: <Purchase /> },
      { path: 'inventory/sale', element: <Sales /> },
      { path: 'inventory/service', element: <Service /> },
      { path: 'inventory/service/:serviceID', element: <Service /> },
      { path: 'inventory/sale/:saleID', element: <Sales /> },
      { path: 'inventory/sales-return/sale_id=:salesID', element: <SalesReturn /> },
      { path: 'inventory/sales-return', element: <SalesReturn /> },
      { path: 'inventory/sales-return/:saleReturnID', element: <SalesReturn /> },
      { path: 'inventory/purchase-return', element: <PurchaseReturn /> },
      { path: 'inventory/purchase-return/:purchaseReturnID', element: <PurchaseReturn /> },
      { path: 'inventory/purchase-order', element: <PurchaseOrder /> },
      { path: 'inventory/purchase-order/:purchaseOrderID', element: <PurchaseOrder /> },
      { path: 'inventory/sale-order', element: <SaleOrder /> },
      { path: 'inventory/sale-order/:saleOrderID', element: <SaleOrder /> },
      { path: 'inventory/delivery-note', element: <DeliveryNote /> },
      { path: 'inventory/delivery-note/:deliveryNoteID', element: <DeliveryNote /> },
      { path: 'inventory/delivery-note-receipt', element: <DeliveryNoteReceipt /> },
      { path: 'inventory/delivery-note-receipt/:deliveryNoteReceiptID', element: <DeliveryNoteReceipt /> },
      { path: 'inventory/internal-stock-transfer', element: <InternalStockTransfer /> },
      { path: 'inventory/internal-stock-transfer/:internalStockTransferID', element: <InternalStockTransfer /> },
      { path: 'inventory/external-stock-transfer', element: <ExternalStockTransfer /> },
      { path: 'inventory/external-stock-transfer/:externalStockTransferID', element: <ExternalStockTransfer /> },
      { path: 'inventory/quotation', element: <Quotation /> },
      { path: 'inventory/quotation/:quotationID', element: <Quotation /> },
      { path: 'inventory/quotation/clone_id=:quotationCloneID', element: <Quotation /> },
      { path: 'inventory/quotation-request-issue', element: <QuotationRequestIssue /> },
      { path: 'inventory/quotation-request-issue/:quotationRequestIssueID', element: <QuotationRequestIssue /> },
      { path: 'inventory/quotation-request-received', element: <QuotationRequestReceived /> },
      { path: 'inventory/quotation-request-received/:quotationRequestReceivedID', element: <QuotationRequestReceived /> },
      { path: 'inventory/stock-adjustment', element: <StockAdjustment /> },
      { path: 'inventory/stock-adjustment/:stockAdjustmentID', element: <StockAdjustment /> },
      { path: 'inventory/bill-wise-margin/:saleID', element: <Sales /> },
      { path: 'inventory/item-wise-margin/:purchaseID', element: <Purchase /> },
      
      { path: 'pos', element: <POS /> },
      { path: 'printsettings/choosetemplate', element: <ChooseTemplate /> },
      { path: 'printsettings/choosethermaltemplate', element: < ChooseTemplateThermal /> },
      { path: 'printsettings', element: <Print /> },
      { path: 'dashboardsettings', element: <DashboardOptions /> },
      { path: 'options', element: <Options /> },
      { path: 'general-settings', element: <GeneralSettings /> },
      { path: 'barcode-settings', element: <BarcodeSettings /> },
      { path: 'work-period', element: <WorkPeriod /> },
      { path: 'subscriptions', element: <Subscriptions /> },
      { path: 'add-payment', element: <AddPayment /> },
      { path: 'maintenance', element: <Maintenance />},
      { path: 'base-currency-adjustments', element: <BaseCurrencyAdjustment />},
    ]
  },
  //Not Found Page Routes
  { path: '*', element: <Status404 /> },
];

export default routes;